import React, { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LanguageIcon from '@mui/icons-material/Language'
import { motion } from 'framer-motion'
import { changeLanguage } from 'i18next'

import { useAuth, useLeftDrawer, useNavigation, useToken } from '@/hooks'

import { PATHS } from '@/constants/path'
import { slideLists } from '@/constants/sideBar'

import { TranslationKeys } from '@/_types_/i18next'

import { SideBar } from '../templates/sideBar/SideBar'

export const ContentLayout = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  const tokenStore = useToken()
  const { isAuthenticated, userData, checkRolePermission } = useAuth()

  if (!isAuthenticated || (!tokenStore.userId && isAuthenticated)) {
    return <Navigate to={PATHS.login} replace />
  }

  const checkRoutePermission = () => {
    const currentPath = location.pathname
    return checkRolePermission(currentPath)
  }

  if (userData.data?.roleId && !checkRoutePermission()) {
    return <Navigate to={PATHS.workOrder} replace />
  }

  return (
    <Box className="flex  min-h-full w-screen flex-col " bg="#f4f4f4">
      <ContentHeader />

      <motion.div
        className="relative flex-1"
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', stiffness: 100 }}
      >
        <SideBar lists={slideLists} />

        <ContentBody>{children}</ContentBody>
      </motion.div>
    </Box>
  )
}

const ContentHeader = () => {
  const { t, i18n } = useTranslation()
  const { logout } = useAuth()
  const leftDrawer = useLeftDrawer()
  const navigation = useNavigation()

  const currentRouteName = useMemo(() => {
    return t(navigation.currentPath, '')
  }, [navigation])

  // const userName = useMemo(() => {
  //   return user.data?.name ?? ''
  // }, [user.data])

  const handleLogout = () => {
    logout()
  }
  const handleChangeLang = (lang: string) => {
    changeLanguage(lang)
    localStorage.setItem('i18nextLng', lang)
  }

  return (
    <Box
      className="fixed left-0 top-0 z-[99999] flex w-full flex-col text-white"
      bg="blue.500"
    >
      <div className="flex flex-row items-center justify-between ">
        <div className="flex flex-row items-center gap-2 p-4">
          <HamburgerIcon fontSize={23} onClick={leftDrawer.toggle} />
          <div className="ml-3 cursor-default text-xl">{currentRouteName}</div>
        </div>
        <div className="relative flex flex-row  items-center gap-2 p-2 pr-[1.5rem]">
          {/* translate */}
          <Menu isLazy>
            <MenuButton>
              <div className="flex h-full items-center justify-center gap-2">
                <Icon as={LanguageIcon} fontSize={24} cursor="pointer" />
                <div>{t(`language.${i18n.language}` as TranslationKeys)}</div>
              </div>
            </MenuButton>
            <MenuList>
              <MenuItem
                color={'black'}
                key={'zh'}
                onClick={() => handleChangeLang('zh')}
              >
                繁體中文
              </MenuItem>
              {/* <MenuItem
                color={'black'}
                key={'en'}
                onClick={() => handleChangeLang('en')}
              >
                English
              </MenuItem> */}
              <MenuItem color={'black'} onClick={() => handleChangeLang('vi')}>
                Tiếng Việt
              </MenuItem>
            </MenuList>
          </Menu>

          {/* info */}
          <div className="ml-5 ">
            <Menu isLazy>
              <MenuButton>
                <Icon as={AccountCircleIcon} fontSize={28} cursor="pointer" />
              </MenuButton>
              <MenuList>
                {/* MenuItems are not rendered unless Menu is open */}
                {/* <MenuItem color={'black'}>{t('AccountManagement')}</MenuItem> */}
                <MenuItem color={'black'} onClick={handleLogout}>
                  {t('Logout')}
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
      </div>
    </Box>
  )
}

const ContentBody = ({ children }: PropsWithChildren) => {
  return (
    <motion.div className="pl-[3.5rem] pt-[3.75rem]">{children}</motion.div>
  )
}
