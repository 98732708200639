import { MdDelete, MdList, MdModeEdit } from 'react-icons/md'
import { Icon } from '@chakra-ui/react'

import { I18nText } from '@/components/atoms/textual/I18nText'

import { useNavigation } from '@/hooks'

import {
  WorkerstateTableColumnHelper,
  WorkStationSectionTableColumnHelper,
} from '@/helpers/user'

import { PATHS } from '@/constants/path'

import i18n from '@/locale/i18n'

import {
  StationSectionTableColumns,
  WorkerstateDataTableColumns,
} from '../UserData'

export const WorkStationSectionColumns: ({
  showModal,
}: {
  showModal: (data: StationSectionData, modal: ModalOpenType) => void
}) => StationSectionTableColumns = ({ showModal }) => {
  const navigation = useNavigation()
  return [
    WorkStationSectionTableColumnHelper.accessor('stationSectionCode', {
      header: () => <I18nText id={'WorkStationSection'} />,
    }),
    WorkStationSectionTableColumnHelper.display({
      id: 'edit',
      header: () => <I18nText id={'Edit'} extraKey={{ what: '' }} />,
      meta: {
        getCellContext: () => {},
        headerTextAlign: 'right',
      },
      cell: (info) => (
        <div className="flex items-center justify-end gap-[1rem]">
          <Icon
            as={MdModeEdit}
            fontSize={22}
            color={'gray.500'}
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation()
              showModal(info.row.original, 'update')
            }}
          />
          <Icon
            as={MdList}
            fontSize={22}
            color={'gray.500'}
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation()
              const stationSectionId = info.row.original.stationSectionId
              const path = PATHS.stationSectionDetail.replace(
                ':stationSectionID',
                `${stationSectionId}`,
              )
              navigation.goTo(path)
            }}
          />
          <Icon
            as={MdDelete}
            fontSize={22}
            cursor="pointer"
            color={'red.600'}
            onClick={(e) => {
              e.stopPropagation()
              showModal(info.row.original, 'delete')
            }}
          />
        </div>
      ),
    }),
  ]
}
export const WorkStationSectionChildColumns: () => WorkerstateDataTableColumns =
  () => [
    WorkerstateTableColumnHelper.accessor('code', {
      header: () => <I18nText id={'WorkerState'} />,
      cell: ({ row }) => {
        const lang = i18n.language
        const localeToKeyMap = {
          zh: row.original.nameZhTW,
          en: row.original.nameVi,
          vi: row.original.nameVi,
        }

        return localeToKeyMap[lang] || row.original.nameZhTW
      },
    }),
  ]
