import { MdDelete, MdModeEdit } from 'react-icons/md'
import { Icon } from '@chakra-ui/react'

import { I18nText } from '@/components/atoms/textual/I18nText'

import {
  DefectiveListTableColumnHelper,
  DefectiveTableColumnHelper,
  DefectiveTypeTableColumnHelper,
} from '@/helpers/tableColumn'

import i18n from '@/locale/i18n'

import { formatDate } from '../Date'
import {
  DefectiveDataTableColumns,
  DefectiveListTableColumns,
  DefectiveTypeTableColumns,
} from '../UserData'

export const DefectiveDataColumns: DefectiveDataTableColumns = [
  DefectiveTableColumnHelper.accessor('createdAt', {
    header: () => <I18nText id={'Date'} />,
    cell: ({ getValue }) => formatDate(getValue()),
  }),
  DefectiveTableColumnHelper.accessor('workOrderCode', {
    header: () => <I18nText id={'workOrder.kanbans.WorkOrderId'} />,
  }),
  DefectiveTableColumnHelper.accessor('station', {
    header: () => <I18nText id={'Station'} />,
  }),
  DefectiveTableColumnHelper.accessor('kanbanCode', {
    header: () => <I18nText id={'Kanban_'} extraKey={{ _: i18n.t('Code') }} />,
  }),
  DefectiveTableColumnHelper.accessor('defectiveCategoryType', {
    header: () => <I18nText id={'DefectiveType'} />,
  }),
  DefectiveTableColumnHelper.accessor('defectiveCategoriesZhTW', {
    header: () => <I18nText id={'DefectiveReason'} />,
    cell: ({ row }) => {
      const lang = i18n.language
      const localeToKeyMap = {
        zh: row.original.defectiveCategoriesZhTW,
        en: row.original.defectiveCategoryVi,
        vi: row.original.defectiveCategoryVi,
      }

      return localeToKeyMap[lang] || row.original.defectiveCategoriesZhTW
    },
  }),
  DefectiveTableColumnHelper.accessor('quantity', {
    header: () => <I18nText id={'Quantity'} />,
  }),
  DefectiveTableColumnHelper.accessor('defectivePhotos', {
    header: () => <I18nText id={'Photo'} />,
    size: 200,
    cell: ({ getValue }) => {
      const src = getValue()
      return (
        <div className="relative aspect-square w-full overflow-hidden">
          <img
            src={src ? src : '/frame.png'}
            alt=""
            className="absolute left-0 top-0 block w-full object-cover object-center "
          />
        </div>
      )
    },
  }),
]

export const useDefectiveTypeColumns: (
  language: string,
  handleAction: (type: ModalOpenType, data?: any) => void,
) => DefectiveTypeTableColumns = (language, handleAction) => [
  DefectiveTypeTableColumnHelper.accessor('code', {
    header: () => <I18nText id="defectiveCategoryCode" />,
  }),
  DefectiveTypeTableColumnHelper.accessor('type', {
    header: () => <I18nText id="defectiveCategoryName" />,
  }),
  DefectiveTypeTableColumnHelper.display({
    id: 'edit',
    header: () => <I18nText id={'Edit'} extraKey={{ what: '' }} />,
    meta: { headerTextAlign: 'right' },
    size: 20,
    cell: (info) => {
      return (
        <div className="flex justify-end">
          <Icon
            as={MdModeEdit}
            fontSize={22}
            cursor="pointer"
            color={'gray.500'}
            onClick={(e) => {
              e.stopPropagation()
              handleAction('update', info.row.original)
            }}
          />
        </div>
      )
    },
  }),
  DefectiveTypeTableColumnHelper.display({
    id: 'invalid',
    header: () => <I18nText id="Invalid" />,
    meta: { headerTextAlign: 'right' },
    size: 20,
    cell: (info) => {
      return (
        <div className="flex justify-end">
          <Icon
            as={MdDelete}
            fontSize={22}
            cursor="pointer"
            color={'gray.500'}
            onClick={(e) => {
              e.stopPropagation()
              handleAction('delete', info.row.original)
            }}
          />
        </div>
      )
    },
  }),
]

export const useDefectiveListColumns: (
  language: string,
  handleAction: (type: ModalOpenType, data?: any) => void,
) => DefectiveListTableColumns = (language, handleAction) => [
  DefectiveListTableColumnHelper.accessor('typeCode', {
    header: () => <I18nText id="defectiveCategoryCode" />,
  }),
  DefectiveListTableColumnHelper.accessor('type', {
    header: () => <I18nText id="defectiveCategoryName" />,
  }),
  DefectiveListTableColumnHelper.accessor('reasonCode', {
    header: () => <I18nText id="defectiveCode" />,
  }),
  DefectiveListTableColumnHelper.accessor(language === 'vi' ? 'vi' : 'zhTW', {
    header: () => <I18nText id="defectiveName" />,
  }),
  DefectiveListTableColumnHelper.display({
    id: 'edit',
    header: () => <I18nText id={'Edit'} extraKey={{ what: '' }} />,
    meta: { headerTextAlign: 'right' },
    size: 20,
    cell: (info) => {
      return (
        <div className="flex justify-end">
          <Icon
            as={MdModeEdit}
            fontSize={22}
            cursor="pointer"
            color={'gray.500'}
            onClick={(e) => {
              e.stopPropagation()
              handleAction('update', info.row.original)
            }}
          />
        </div>
      )
    },
  }),
  DefectiveListTableColumnHelper.display({
    id: 'invalid',
    header: () => <I18nText id="Invalid" />,
    meta: { headerTextAlign: 'right' },
    size: 20,
    cell: (info) => {
      return (
        <div className="flex justify-end">
          <Icon
            as={MdDelete}
            fontSize={22}
            cursor="pointer"
            color={'gray.500'}
            onClick={(e) => {
              e.stopPropagation()
              handleAction('delete', info.row.original)
            }}
          />
        </div>
      )
    },
  }),
]
